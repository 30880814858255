<template>
  <div ref="gsiOverlay" id="gsi_overlay" class="gsiOverlay"></div>
  <div ref="oneTapOverlay" id="oneTapOverlay">
    <div class="oneTapOverlay-inner">
        <div class="oneTapOverlay-text">
            <div class="oTO-Text-Logo">
                <img src="../../assets/image/businessline-epaper.png" alt="The Hindu ePaper" />
            </div>
            <div class="oTO-Text">
              <div class="oTO-Text-title">Sign in to Unlock member-only benefits!</div>
              <ul class="oTO-Text-points">
                  <li>Access 2 free pages every day</li>
                  <li>Sign-up/manage your newsletter subscriptions with a single click</li>
                  <li>Save stories for offline reading</li>
              </ul>
            </div>
        </div>
        <div class="oneTapOverlay-arrow">
            <img src="../../assets/image/group-12945.svg" alt="Sign in" />
        </div>
    </div>
  </div>
</template>

<script>
import { cleanArticleUrl, getHeaders } from "@/utils/google_signin";

export default {
  name: "GoogleSignIn",
  data() {
    return {
      auth_ep: process.env.VUE_APP_GNI_AUTH_EP,
      response_ep: process.env.VUE_APP_GNI_RESPONSE_EP,
    };
  },
  methods: {
    handleGoogleSignIn(user) {
      const clean_url = cleanArticleUrl();
      const options = {
        method: "POST",
        headers: getHeaders(),
        body: JSON.stringify({
          token: user.credential,
          article_url: clean_url,
          pubId: "THEP",
        }),
      };

      fetch(this.auth_ep, options)
        .then((res) => {
          if (!res.ok) throw new Error(res.statusText);
          return res.json();
        })
        .then((res) => {
          if (res.redirect_uri) {
            this.$logger.debug("redirect_uri is present", res.redirect_uri);
            setTimeout(() => {
              window.location.replace(res.redirect_uri);
            }, 200);
          } else if (
            res.token &&
            res.token.trim() !== "" &&
            window?.tp?.pianoId
          ) {
            this.$logger.debug("token is present", res.token);
            this.LoginByTokenAndReload(res.token);
          }
        })
        .catch((err) => console.error(err));
    },
    handleResponseUrl() {
      const url_obj = new URL(window.location.href);
      const response_id = url_obj.searchParams.get("response_id");
      const redirect_uri = url_obj.searchParams.get("internal_redirect_uri");

      if (!response_id || !redirect_uri) {
        if (!window.tp?.pianoId?.isUserValid()) {
          setTimeout(() => {
            window.google?.accounts.id.prompt(this.MomentListener);
          }, 200);
        }
        return;
      }
      // If user is already logged in, Do nothing with response_id or redirect_uri
      // else if (window.tp && window.tp.pianoId.isUserValid()) return;

      const options = {
        method: "POST",
        headers: getHeaders(),
        body: JSON.stringify({
          response_id: response_id,
          pubId: "BLEP",
        }),
      };

      fetch(this.response_ep, options)
        .then((res) => {
          if (!res.ok) throw new Error(res.statusText);
          return res.json();
        })
        .then((res) => {
          this.$logger.debug("response from handleResponseUrl", res);
          // return if response is not ok OR no access token is present
          if (!res?.data?.status === "ok" || !res?.data?.access_token) {
            this.$logger.debug(
              "response is not ok OR no access token is present"
            );
            return;
          }
          if (window?.tp?.pianoId) {
            const cb = () => this.LoginByTokenAndReload(
              res?.data?.access_token, res?.data?.redirect_uri
            );
            window.tp.push(["init", cb]);
          } else window.location.replace(res?.data?.redirect_uri);
        })
        .catch((err) => console.error("Error: ", err));
    },
    LoginByTokenAndReload(token, redirect_uri=null) {
      window?.dataLayer?.push({
        event: "onetap_signin",
        data: {
          click: "sign_in",
          click_type: "action",
          general_placement: "body",
          detailed_placement: "",
          goal_type: "onetap_signin",
        },
      });

      window.tp.pianoId.loginByToken(token);
      setTimeout(() => {
        if (redirect_uri) {
          window.location.replace(redirect_uri);
        } else {
          window.location.reload();
        }
      }, 700);
    },
    MomentListener(notification) {
      this.$logger.debug("MomentListener fired...");
      this.$logger.debug(notification);
      const momentType = notification.getMomentType();
      this.$logger.debug("Moment type", momentType);

      if (momentType === "display" && notification.isDisplayed()) {
        this.$logger.debug("OneTap is displayed");
        this.$refs.gsiOverlay.style.display = "block";
        this.$refs.oneTapOverlay.style.display = "block";

        window?.dataLayer?.push ({
          'event' : 'onetap_signin',
          'data' : {
            'click':'view',
            'click_type' : '',
            'general_placement':'body',
            'detailed_placement':'',
            'goal_type':'onetap_signin_view'
          }
        });
      } else {
        this.$logger.debug("dismissed");
        this.$refs.gsiOverlay.style.display = "none";
        this.$refs.oneTapOverlay.style.display = "none";
      }
    },
  },
  mounted() {
    if (!window?.tp?.pianoId.isUserValid()) {
      this.$logger.debug("User not logged in.. Initializing gsi sdk..");
    } else {
      this.$logger.debug("User is already logged in");
    }

    window.document.addEventListener("DOMContentLoaded", () => {
      setTimeout(() => {
        this.$logger.debug("DOMContentLoaded event fired..");
        this.$logger.debug(process.env.VUE_APP_GOOGLE_CLIENT_ID);
        window.google?.accounts.id.initialize({
          client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
          callback: this.handleGoogleSignIn,
          itp_support: true,
        });
        this.handleResponseUrl();
      }, 1500);
    });
  },
};
</script>

<style scoped>
.gsiOverlay {
  position: fixed;
  top: 0;
  background-color: #000;
  opacity: 0.65;
  width: 100%;
  height: 100%;
  z-index: 9999;
  cursor: auto;
  left: 0;
  display: none;
}
#oneTapOverlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.9);
  display: none;
}
.oneTapOverlay-inner {
  margin-right: 420px;
  margin-left: auto;
  width: calc(100% - 420px);
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 80px;
}
.oTO-Text-Logo img {
  width: 59px;
  height: 59px;
}
.oneTapOverlay-text {
  display: flex;
  flex-direction: row;
  margin-top: 57px;
  max-width: 550px;
}
.oTO-Text {
  padding: 0 16px;
  color: #FFFFFF;
}
.oTO-Text-title {
  font-size: 20px;
  line-height: 28px;
  font-family: "Merriweather",serif;
  font-weight: bold;
  margin: 15px 0px;
}
.oTO-Text-subtitle {
  font-size: 20px;
  margin: 16px 0 8px 0;
  font-family: "Merriweather",serif;
  font-weight: bold;
}
.oTO-Text-points {
padding: 0;
position: absolute;
}
.oTO-Text-points li {
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 8px;
  font-family: "Faustina",serif;
  list-style-type: none;
  position: relative;
  display: flex;
  align-items: flex-start;
}
.oTO-Text-points li::before {
content: '';
display: block;
padding: 8px 7px;
background: url(../../assets/image/arrow-819.svg) no-repeat center center;
background-size: contain;
margin-right: 8px;
margin-top: 6px;
}
.oneTapOverlay-arrow img {
  max-width: 100%;
}
@media screen and (max-width: 1250px) {
  .oneTapOverlay-arrow {
   display: none
  }
}
@media screen and (max-width: 820px) {
  .oTO-Text-title {
      font-size: 24px;
  }
  .oTO-Text-subtitle {
      font-size: 18px;
  }
  .oTO-Text-points li {
      font-size: 16px;
  }
  .oneTapOverlay-text {
      flex-direction: column;
      align-items: center;
  }
}
@media screen and (max-height: 500px) {
  .oneTapOverlay-text {
      margin-top: 0;
  }
  .oTO-Text-title {
      font-size: 24px;
  }
  .oTO-Text-subtitle {
      font-size: 18px;
  }
  .oTO-Text-points li {
      font-size: 16px;
  }
}
</style>
