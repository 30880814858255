/**
 * List of events to be listened for...
 */

export default [
  "print_or_download_overview_download_button_click",
  "pages_print_issue_button_click",
  "pages_download_issue_button_click",
  "article_print_button_click",
  "article_share_button_click",
  "article_share_opened",
  "article_article_view",
  "pages_page_view",
  "add_article_to_fav_internal",
  "remove_article_from_fav_internal",
  "pages_view",
  "article_view",
  "article_list_view",
  "scrubber_view",
  "print_or_download_overview",
  "pages_scrubber_button_click",
  "pages_fullscreen_mode_on_button_click",
  "pages_fullscreen_mode_off_button_click",
  "pages_intersitial_page_view",
  "pages_ad_click_on_page",
  "pages_zoom_out_double_click",
  "pages_zoom_in_double_click",
  "pages_zoom_out_touch_pinch",
  "pages_zoom_in_touch_pinch",
  "pages_zoom_out_trackpad_pinch",
  "pages_zoom_in_trackpad_pinch",
  "pages_zoom_out_button_click",
  "pages_zoom_in_button_click",
  "pages_crop_button_click",
  "pages_article_list_button_click",
  "pages_group_navigation_bar_select_group",
  "crop_ok_button_click",
  "article_list_scroll_view",
  "article_list_cell_click",
  "print_or_download_overview_page_select",
  "print_or_download_overview_print_button_click"
]