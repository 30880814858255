import { emitUserFavList, emitFavListResponse, emitUserType } from "./dependantEvents"
import { addOrRemoveFavourites, getFavouriteArticlesIds } from "@/request-handlers/favourites"
import { pushArticleViewEventToDataLayer, pushArticleViewUserEventToDataLayer } from "./dataLayerEvents"
import Logger from "./logToConsole"
import { setItem } from "@/utils/setCookie"

const MatomoTrackDetails = (ev, detail) => ['trackEvent', 'click', ev, detail, 1]

const PushMatomoEvents = (ev, detail) => {
    if (window._paq) {
        window._paq.push(
            MatomoTrackDetails(ev, detail)
        )
    }
}

const initFavouritesList = (store) => {
    if (store.state.reader.favouriteArticles === null) {
        Logger.debug("Favourites not loaded...")
        getFavouriteArticlesIds()
            .then(res => {
                if (res.data && Array.isArray(res.data)) {
                    emitUserFavList(res.data)
                    store.state.reader.favouriteArticles = res.data
                }
                else if (res.status && res.status === false) emitFavListResponse(false)
            })
            .catch(() => emitFavListResponse(false))
    } else emitUserFavList(store.state.reader.favouriteArticles)
}

const PagesView = (store, e) => {
    store.commit('reader/changeLoadingState', false)
    initFavouritesList(store)
    emitUserType(store)

    const userAgent = navigator.userAgent;
    const openinapp = document.getElementById("open_in_app")

    if (
        (userAgent.match(/iPad|iphone|iPod|Android/i)) &&
        openinapp != undefined
      )
        {
            openinapp.style.display  = "block";
        }

    // Set current [page_layout_desk] in cookie
    if (e.detail.page_layout_desk) {
        document.cookie = "_pc_pageName=" + e.detail.page_layout_desk
        document.cookie = "_pc_editionName=" +e.detail.publication_name

        window.tp?.experience.execute()
    }
    // Push current [page_internal_name] to piano to block pageView
    if (e.detail.page_internal_name) {
        Logger.debug("tp custom variable pagename: " + e.detail.page_internal_name);
        window.tp?.push(["setCustomVariable", "pagename", e.detail.page_internal_name]);
        window.tp?.push(["setCustomVariable", "Editionname", e.detail.publication_name]);
    }
    //page number stored in session
    if(e.detail.page_number){
        console.log("current page number",e.detail.page_internal_id)
        setItem('pageInternalId',e.detail.page_internal_id) 
    }
    // Send articleViewUser event to dataLayer on first page load...
    if (store.state.reader.isArticleViewUserEventFired == false) {
        pushArticleViewUserEventToDataLayer(e.detail)
        store.commit('setArticleViewUserEventFired')
        Logger.debug("pushArticleViewUserEventToDataLayer fired...");
    }

    if (window?.tp?.pianoId?.isUserValid()) {
        store.dispatch('reader/checkUserSession')
    }
}

const ArticleView = (store, e) => {
    // Push [articleView] event to matomo
    if (e.detail.article_internal_id) {
        store.state.reader.currentArticeId = e.detail.article_internal_id
        PushMatomoEvents('articleView', e.detail.article_internal_id)
        pushArticleViewEventToDataLayer(e.detail)
        window.tp.experience.execute()
    }

    // Push current [page_internal_name] to piano to block pageView
    if (e.detail.page_internal_name) {
        Logger.debug("[from articleViewEvent] tp custom variable pagename: " + e.detail.page_internal_name);
        window.tp?.push(["setCustomVariable", "pagename", e.detail.page_internal_name]);
    }
}

const FavAction = (store, e) => {
    const action = e.type === "remove_article_from_fav_internal" ? "remove" : "add"
    const userDetails = window.tp.pianoId.getUser()
    const userId = userDetails ? userDetails.uid : null

    if (userId === null) alert("Please login to add articles to your favourites list.");
    else addOrRemoveFavourites(store.state.reader.currentArticeId, action)
        .then(res => {
            if (res.status && res.status === false) emitFavListResponse(false)
            else if (res.status) {
                if (action === "add") {
                    store.state.reader.favouriteArticles.push(
                        store.state.reader.currentArticeId
                    )
                }
                else if (action === "remove") {
                    const index = store.state.reader.favouriteArticles.indexOf(
                        store.state.reader.currentArticeId
                    );
                    if (index > -1)
                        store.state.reader.favouriteArticles.splice(index, 1);
                }
            }
        })
        .catch(() => emitFavListResponse(false))
}

const DownloadOrShareButtonClicked = (_, e) => {
    if (e.type === "article_print_button_click" && e.detail.issue_internal_id)
        PushMatomoEvents('articleDownload', e.detail.issue_internal_id)

    else if (e.type === "article_share_opened" && e.detail.article_internal_id)
        PushMatomoEvents('articleShare', e.detail.article_internal_id)

    else return
}

/** 
 * This function could be used to track all other events,
 * which are not tracked specifically.
 */
const CommonEventTracking = (e) => {
    const event_details = {
        "event": "reader_event",
        "data": { "event_name": e.type }
    }

    window.dataLayer?.push(event_details)
}

/**
 * Event CallBacks
 * ===============
 *
 * Each `eventID` is mapped to a specific function,
 * which will be called whenever an event with `eventID` occurs...
 *
 * Each CallBack must accept two arguments. The following
 * is the prototype of event CallBack:
 *
 * +++++++++++++++++++++++++++++++++++++
 * const CallBack_function = (store, event_details) => {
 *      // Do your desired action here... You will be having
 *      // access to $store and `event_details`
 * }
 * +++++++++++++++++++++++++++++++++++++
 *
 * Default recommended code-style would be, to create
 * a lean function, masking the logics to another module.
 *
 * Steps for adding a new event listener...
 *      1. If the event is not listed, Add the `eventID` to `src/utils/events.js`
 *      2. Create a `CallBack` function in this file.
 *      3. Export the `CallBack` as below
 *          +++++++++++++++++++++
 *          `eventID`: CallBack
 *          +++++++++++++++++++++
 * The `CallBack` by default would be supplied with `$store` and the `eventDetails`
 */

export default {
    'pages_page_view': PagesView,
    'article_article_view': ArticleView,
    'add_article_to_fav_internal': FavAction,
    'remove_article_from_fav_internal': FavAction,
    'article_print_button_click': DownloadOrShareButtonClicked,
    'article_share_opened': DownloadOrShareButtonClicked,
    'common_event_tracking': CommonEventTracking
}