import Logger from "@/utils/logToConsole"

const getPreferredEdition = (data) => {
    let preferredEdition = null
    if (data.custom_field_values) {
        const customFieldValues = data.custom_field_values
        const preferredEditionField = customFieldValues.filter(
            el => el.field_name === 'thg_edition'
        )
        if (Array.isArray(preferredEditionField) && preferredEditionField.length > 0) {
            try {
                const preferredEditionValue = JSON.parse(preferredEditionField[preferredEditionField.length-1].value)
                Logger.info(" preferredEditionValue ")
                Logger.info(preferredEditionValue)
                preferredEdition = preferredEditionValue
            } catch (err) {
                Logger.error("Get preferredEditionValue error: ")
                Logger.error(err)
            }
        } else Logger.error("Get preferredEditionValue error: No preferred edition set...")
    } else Logger.error("Get preferredEditionValue error: No custom field values present...")

    if (Array.isArray(preferredEdition) && preferredEdition.length == 1) {

        return {
            id: process.env.VUE_APP_PUB + "_" + preferredEdition[0].trim().toLowerCase(),
            title: preferredEdition[0]
        }
}

    return { id: process.env.VUE_APP_PUB + "_" + "mumbai", title: "Mumbai" }
}

export default getPreferredEdition