<template>
<footer>
    <div class="container">
        <div class="row footer-logo">
            <div class="col-xl-6 col-md-6 col-sm-6 col-xs-12 col-12">
                <a href="#_"><img :src="logoPath" class="img-fluid mobile-logo" alt="BusinessLine Logo"></a>
            </div>
            <div class="col-xl-6 col-md-6 col-sm-6 col-xs-12 col-12">
                <div class="app-icon">
                    <a href="https://play.google.com/store/apps/details?id=com.epaper.thehindubusinessline.android" title="Google Play"><img src="../../assets/image/google-playstore-icon.svg" alt="Google Play"></a>
                    <a href="https://apps.apple.com/in/app/businessline-epaper/id1563008614" title="Apple Store"><img src="../../assets/image/apple-store-icon.svg" alt="Apple Store"></a>
                </div>
            </div>
        </div>
        <div class="row footer-menu">
          <div class="col-xl-2 col-md-2 col-sm-3 col-xs-12 col-12">
            <ul class="footer-list" >
              <li class="title" v-for="(item, index) in menu.slice(0, 2)" :key="index">
                <h5 :class="index == 1 ? 'no-border' : ''" ><span class="footer-arrow">{{item.title}}</span></h5>
                <ul class="footer-sub-list"  >
                  <li v-for="childitem in item.children" :key="childitem.title"><a :href=childitem.href>{{childitem.title}}</a></li>
                </ul>
                
              </li>
            </ul>
          </div>
          <div class="col-xl-2 col-md-2 col-sm-3 col-xs-12 col-12" v-for="item in menu.slice(2)" :key="item.id">
            <ul class="footer-list">
              <li class="title">
                <h5 class=""><span class="footer-arrow">{{item.title}}</span></h5>
                <ul class="footer-sub-list">
                  <li v-for="childitem in item.children" :key="childitem.title"><a :href=childitem.href>{{childitem.title}}</a></li>
                </ul>
              </li>
            </ul>
          </div>
          <FooterpageTrending></FooterpageTrending>
        </div>
        <div class="row pb-5">
            <div class="col-xl-12 col-md-12  col-sm-12 col-xs-12 col-12">
                <ul class="footer-social-icon">
                    <li><a href="https://www.facebook.com/TheHinduBusinessLine" title="Facebook"><img src="../../assets/image/footer-icon-fb.svg" alt="Facebook" title="Facebook"></a></li>
                    <li><a href="https://twitter.com/businessline" title="Twitter"><img src="../../assets/image/footer-icon-x.svg" alt="Twitter" title="Twitter"></a></li>
                    <li><a href="https://www.instagram.com/hindu_businessline/" title="Instagram"><img src="../../assets/image/footer-icon-insta.svg" alt="Instagram" title="Instagram"></a></li>
                    <li><a href="https://in.linkedin.com/company/hindubusinessline" title="Linkedin"><img src="../../assets/image/footer-icon-linkedin.svg" alt="LinkedIn" title="LinkedIn"></a></li>
                    <li><a href="https://www.youtube.com/user/HinduBusinessLine" title="Youtube"><img src="../../assets/image/footer-icon-youtube.svg" alt="YouTube" title="YouTube"></a></li>
                    <li><a href="https://open.spotify.com/show/0dT640TEOm9S2ThjMpTCnV" title="Drizzle"><img src="../../assets/image/footer-icon-drizzle.svg" alt="Drizzle" title="Drizzle"></a></li>
                </ul>
                <ul class="link-list">
                    <li><a href="https://www.thehindugroup.com/privacy.html">Terms of Use</a></li>
                    <li><a href="https://www.thehindugroup.com/termsofuse.html">Privacy policy</a></li>
                </ul>
                <div class="copyrights">
                  <a>Copyright© 2022, THG PUBLISHING PVT LTD. or its affiliated companies. All rights reserved.</a>
                </div>
                <div class="back-to-top">
                    <p>BACK TO TOP<img src="../../assets/image/back-to-top-arrow.svg" alt="Arrow Icon"></p>
                </div>
            </div>
            
        </div>
    </div>
</footer>
</template>

<script>
import Footer from './footer.json'
import FooterpageTrending from '@/components/RXLoginPage/FooterpageTrending.vue'

export default {
  components: {
		FooterpageTrending
  },
  mounted(){
  window.$(document).ready(function(){
    window.$(".back-to-top").click(function () {
      window.$("html, body").animate({scrollTop: 0}, 100);
    });
  });
  },
  data(){
    return{
      menu: Footer,
      footerHtml: "",
      logoPath: process.env.BASE_URL + process.env.VUE_APP_LOGO_RX_FILE
    }
  }
}
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Merriweather+Sans:wght@400;600;700;900&family=Merriweather:wght@400;700;900&family=Fira+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Merriweather+Sans:wght@400;600;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;700;900&display=swap");
* {
  font-family: "Merriweather", serif;
  font-weight: normal;
}

.container {
  max-width: 1300px;
}

a, button {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
}
a:hover, button:hover {
  color: inherit;
}

footer {
  background-color: #F2F2F2;
}
footer .app-icon {
  display: inline;
  float: right;
}
footer .app-icon img {
  width: 128px;
  height: 38px;
  margin-left: 15px;
}
footer .footer-top-border {
  border-top: 5px solid #000;
  padding-top: 7px;
}
footer .footer-logo {
  width: auto;
  padding: 30px 0px 30px 0px;
}
footer .footer-logo .mobile-logo {
  width: 310px;
  height: 41px;
}
footer .footer-menu h5 {
  font-size: 14px;
  line-height: normal;
  font-family: "Merriweather Sans", sans-serif;
  color: #000000;
  font-weight: 800;
}
footer .footer-menu h5 span {
  font-weight: 800;
  font-family: "Merriweather Sans", sans-serif;
}
footer .footer-menu .footer-menu-list {
  list-style: none;
  display: inline-block;
  padding: 0;
  width: 100%;
}
footer .footer-menu .footer-menu-list li {
  font-size: 14px;
  line-height: normal;
  font-family: "Merriweather Sans", sans-serif;
  color: #000000;
  margin: 2px 0;
  position: relative;
  border-bottom: 1px solid #999999;
  padding: 10px 0;
}
footer .footer-menu .footer-menu-list li a {
  font-family: "Merriweather Sans", sans-serif;
}
footer .footer-menu .footer-menu-list li:last-child {
  border-bottom: none;
}
footer .footer-social-icon {
  list-style: none;
  display: inline-block;
  padding: 0;
  margin: 0 0 20px;
}
footer .footer-social-icon a:hover {
  text-decoration: none;
}
footer .footer-social-icon li {
  display: inline;
  margin: 0 5px 0 0;
}
footer .footer-social-icon li img {
  width: 41px;
  height: 34px;
}
footer .link-list {
  margin: 0 0 10px;
  padding: 0;
  list-style: none;
}
footer .link-list li {
  display: inline-block;
  position: relative;
  margin-right: 15px;
}
footer .link-list li::after {
  display: none;
}
footer .link-list li#teconsent a::before {
  display: none;
}
footer .link-list li a {
  font-size: 12px;
  line-height: normal;
  color: #000000;
  text-decoration: none;
  font-family: "Merriweather Sans", sans-serif;
}
footer .link-list li a:hover {
  text-decoration: none;
}
footer .link-list li a::before {
  color: #6c757d;
  content: "/";
  position: absolute;
  left: -12px;
  top: 5px;
  font-size: 12px;
}
footer .link-list li:first-child a::before {
  display: none;
}
footer .copyrights {
  margin-bottom: 5px;
  color: #000000;
}
footer .copyrights a {
  font-size: 12px;
  line-height: normal;
  font-family: "Merriweather Sans", sans-serif;
  color: #000000;
}

.footer-list {
  padding: 0;
  margin: 0;
  list-style: none;
}
.footer-list .title {
  list-style: none;
}
.footer-list .title:first-child h5 {
  padding: 10px 0 0px;
  margin: 0;
  border-top: 5px solid #000;
}
.footer-list .title .footer-sub-list {
  padding: 0;
  margin: 12px 0 30px;
  list-style: none;
}
.footer-list .title .footer-sub-list li {
  list-style: none;
  font-size: 14px;
  line-height: normal;
  font-family: "Merriweather Sans", sans-serif;
  color: #000000;
  margin: 7px 0;
}
.footer-list .title .footer-sub-list li a {
  padding: 3px 0;
  display: inline-block;
  font-family: "Merriweather Sans", sans-serif;
}

.back-to-top {
  position: relative;
  cursor: pointer;
}
.back-to-top p {
  margin: 0;
  padding: 0;
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: 13px;
  font-family: "Merriweather Sans", sans-serif;
  font-weight: bold;
  padding-right: 25px;
}
.back-to-top p img {
  position: absolute;
  bottom: 3px;
  right: 0;
}

@media screen and (max-width: 570px) {
  footer .top-border-hide-mobile {
    border-top: 0px solid #000;
    padding-top: 0px;
  }
  footer .footer-logo {
    text-align: center;
    padding-bottom: 10px;
  }
  footer .footer-logo .mobile-logo {
    width: 270px;
    height: 34px;
    margin-bottom: 20px;
    margin-left: 0;
    display: block;
  }
  footer .footer-menu .footer-top-border {
    margin-top: 15px;
    border-top: 5px solid #000;
    padding-top: 15px;
  }
  footer .footer-menu-tamil {
    font-size: 13px;
    line-height: 0;
  }
  footer .app-icon {
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: left;
    float: none;
  }
  footer .app-icon img {
    margin: 0 15px 0 0;
    width: 150px;
    height: 45px;
  }
  footer .app-icon a:last-child img {
    margin-right: 0%;
  }
  footer .footer-list {
    padding-left: 0px;
    padding-bottom: 7px;
    padding-top: 7px;
  }
  footer .footer-list h5 {
    position: relative;
    z-index: 2;
    padding: 4px 0;
    margin: 2px 0;
    border-top: none;
  }
  footer .footer-list .title {
    border-bottom: 1px solid #cccccc;
    padding-bottom: 15px;
  }
  footer .footer-list .title:first-child h5 {
    padding: 0px 0 0px;
    margin-top: 10px;
    border-top: none;
  }
  footer .footer-list .title h5 {
    margin: 15px 0 5px;
  }
  footer .footer-list .title li {
    display: inline-block;
    width: 49%;
    vertical-align: top;
  }
  footer .footer-list .title .footer-sub-list {
    margin: 10px 0 0;
  }
  footer .footer-list .title .footer-sub-list li {
    margin: 3px 0;
  }
  footer .footer-list .menu-list-hide-mobile {
    display: none;
  }
  footer .footer-social-icon {
    text-align: left;
  }
  footer .copyrights {
    text-align: left;
    line-height: 20px;
  }
  .col-xl-2:nth-last-child(2) .footer-list:last-child .title:last-child {
    padding-bottom: 0;
    border-bottom: none;
  }
}
@media screen and (max-width: 900px) {
  .back-to-top {
    position: relative;
    display: block;
    width: max-content;
    height: 0px;
    float: left;
    margin-top: 30px;
  }
  .back-to-top p {
    position: relative;
    float: right;
  }
}
h1 a, h2 a, h3 a, h4 a, h5 a, .title-patch a {
  font-family: inherit;
  font-weight: inherit;
  color: inherit;
}

</style>