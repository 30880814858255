import Logger from "./logToConsole";
import UAParser from "ua-parser-js";

const isMobile = {
  Android: function () {
    return navigator.userAgent.match(/Android/i);
  },
  BlackBerry: function () {
    return navigator.userAgent.match(/BlackBerry/i);
  },
  iOS: function () {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i);
  },
  Opera: function () {
    return navigator.userAgent.match(/Opera Mini/i);
  },
  Windows: function () {
    return (
      navigator.userAgent.match(/IEMobile/i) ||
      navigator.userAgent.match(/WPDesktop/i)
    );
  },
  any: function () {
    return (
      isMobile.Android() ||
      isMobile.BlackBerry() ||
      isMobile.iOS() ||
      isMobile.Opera() ||
      isMobile.Windows()
    );
  },
};

export function pushArticleViewEventToDataLayer(eventData) {
  const articleData = {
    event: "articleView",
    data: {
      article_InternalId: eventData?.article_internal_id,
      article_byline: eventData?.article_byline,
      article_headline: eventData?.article_headline,
      Page_Internal_Id: eventData?.page_internal_id,
      Page_Internal_name: eventData?.page_internal_name,
      Page_Layout_desk: eventData?.page_layout_desk,
      Page_Number: eventData?.page_number,
      Page_Section_Name: eventData?.page_section_name,
      Issue_Name: eventData?.issue_name,
      Issue_Publication_Date: eventData?.issue_pub_date,
      Issue_Zone: eventData?.issue_zone,
      Label: eventData?.label,
      Orientation: window.screen.orientation.type,
      OS: window.navigator.platform
    },
  };
  Logger.debug("Pushing articleView event to dataLayer");
  Logger.debug(articleData);
  window?.dataLayer?.push(articleData);
}

export function pushArticleViewUserEventToDataLayer(eventData) {
  const isMainEdition =
    eventData?.publication_name?.replace("EPaper-", "")?.toLowerCase() ==
    eventData?.issue_zone?.toLowerCase();
  const userDetails = window?.tp?.pianoId?.getUser();
  const articleViewUserData = {
    event: "articleViewUser",
    data: {
      email: userDetails?.email,
      uid: userDetails?.uid,
      platform: isMobile.any() ? "mweb" : "desktop",
      edition: eventData?.issue_zone?.toLowerCase(),
      editiontype: isMainEdition
        ? "main edition"
        : eventData?.publication_name?.replace("Epaper-", "")?.toLowerCase(),
      date: eventData?.issue_pub_date,
      view: "iframe",
      loginstatus: window?.tp?.pianoId?.isUserValid() ? "logged" : "not logged",
    },
  };

  Logger.debug("Pushing articleViewUser event to dataLayer");
  Logger.debug(articleViewUserData);
  window?.dataLayer?.push(articleViewUserData);
}

export function userBlockedEvent() {
  const userDetails = window?.tp?.pianoId?.getUser();
  const userAgent = navigator.userAgent;

  const uaParser = new UAParser("user-agent");
  uaParser.setUA(userAgent);
  const uaResult = uaParser.getResult();
  let device =
    (uaResult.device.vendor + " - " + uaResult.device.model) ??
    uaResult.os.name ??
    "Unknown OS";
  if (uaResult.os.version) {
    device += " " + uaResult.os.version;
  }

  window?.dataLayer?.push({
    event: "userLoginBlocked",
    data: {
      registeredEmailId: userDetails.email,
      uid: userDetails.uid,
      platform: isMobile.any() ? "mweb" : "desktop",
      device: device,
      browser: uaResult.browser.name,
    },
  });
}
