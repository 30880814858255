<template>
    <the-header></the-header>
    <page-info-nav heading="FAQ"></page-info-nav>

    <accordion-wrapper :id="parentId" class="container-lg mt-5 px-md-5">
        <!-- Item 1 -->
        <accordion-item id="ac1" :parent-id="parentId" heading="Free Trial">
            <accordion-wrapper id="child-1">
                <accordion-item
                    id="ac-ci-1-1"
                    parent-id="child-1"
                    heading="Is there any trial period available for The Hindu e-Paper?"
                >You will be eligible for a one-time free trial as soon as you sign up. The free trial is valid for 7 days.</accordion-item>
                <accordion-item
                    id="ac-ci-1-2"
                    parent-id="child-1"
                    heading="Will I be able to download the e-Paper during my trial period?"
                >During trial period, users cannot download the newspaper pages and edition, while they can download individual articles in both html and PDF formats</accordion-item>
                <accordion-item
                    id="ac-ci-1-3"
                    parent-id="child-1"
                    heading="Will I be able to access the archives of the ePaper during my trial period?"
                >60 day archive access is available for subscribers and not during the free trial duration</accordion-item>
            </accordion-wrapper>
        </accordion-item>
        <!-- Item 2 -->
        <accordion-item id="ac2" :parent-id="parentId" heading="View Daily e-Paper">
            <accordion-wrapper id="child-2">
                <accordion-item
                    id="ac-ci-2-1"
                    parent-id="child-2"
                    heading="How to read/view articles in e-Paper?"
                >
                    <p>After sign in, select the page that you desire to read from the thumbnails. Below the thumbnails you will find list of articles</p>
                    <ol>
                        <li>Select the desired article to view it alongside the list</li>
                        <li>Click on Icon to open the article in a separate page Icon to open the article in a separate page. You can share the article on social media from this page.</li>
                        <li>Click on icon to see the image view of the article icon to see the image view of the article</li>
                        <li>Cick on to save the article in your favorite list to save the article in your favorite list.</li>
                        <li>Click on to download the PDF version of the article to download the PDF version of the article.</li>
                        <li>Click on to increase the size of body text of the article to increase the size of body text of the article</li>
                    </ol>
                </accordion-item>
                <accordion-item
                    id="ac-ci-2-2"
                    parent-id="child-2"
                    heading="How do I change edition of The Hindu?"
                >
                    <p>After login to e-Paper</p>
                    <ol>
                        <li>
                            Click on
                            <img
                                style="height: 20px; vertical-align: inherit;"
                                src="https://thpianost.avahan.net//img/edition-128.png"
                            /> icon on the tool bar icon on the tool bar
                        </li>
                        <li>Select the required edition from the list</li>
                    </ol>
                </accordion-item>
                <accordion-item
                    id="ac-ci-2-3"
                    parent-id="child-2"
                    heading="Will I be able to access the archives of the e-Paper during my trial period?"
                >
                    <p>After login to e-Paper,</p>
                    <ol>
                        <li>Click on the user icon on the right of the tool bar on the top on the right of the tool bar on the top</li>
                        <li>Select ‘Change Password’ From the menu</li>
                        <li>In the pop up form type your old and new password</li>
                        <li>Click on the ‘Change Password button.</li>
                    </ol>
                </accordion-item>
                <accordion-item
                    id="ac-ci-2-4"
                    parent-id="child-2"
                    heading="I cannot see a different view of e-Paper on Mobile devices."
                >Yes, the desktop version of the site provides three different views of the e-Paper. The thumbview, the listview and fullview. But the mobile/iPAD/Tablet version of the site has only the thumbview. The other two views are not suitable for these devices.</accordion-item>
                <accordion-item
                    id="ac-ci-2-5"
                    parent-id="child-2"
                    heading="How to change Password?"
                >
                    <p>After login to e-Paper,</p>
                    <ol>
                        <li>Click on the user icon on the right of the tool bar on the top on the right of the tool bar on the top</li>
                        <li>Select ‘Change Password’ From the menu</li>
                        <li>In the pop up form type your old and new password</li>
                        <li>Click on the ‘Change Password button.</li>
                    </ol>
                </accordion-item>
                <accordion-item
                    id="ac-ci-2-6"
                    parent-id="child-2"
                    heading="How do I view articles on a mobile?"
                >
                    <p>After login to e-Paper,</p>
                    <ol>
                        <li>Select the page that you desire to read from the thumbnails. Below the thumbnails, you will find list of articles</li>
                        <li>Click the desired article to view</li>
                        <li>Article will open in a new tab on your mobile device</li>
                        <li>If you wish to see the article in image view, click on icon</li>
                    </ol>
                </accordion-item>
                <accordion-item
                    id="ac-ci-2-7"
                    parent-id="child-2"
                    heading="How to save an article as favourite?"
                >
                    <p>After login to e-Paper,</p>
                    <ol>
                        <li>Select the page that you desire to read from the thumbnails. Below the thumbnails, you will find list of articles</li>
                        <li>Select the desired article to view</li>
                        <li>Click on to save the article in your favourite list to save the article in your favourite list Currently, this feature is not available in mobile devices</li>
                    </ol>
                </accordion-item>
            </accordion-wrapper>
        </accordion-item>
        <!-- Item 3 -->
        <accordion-item id="ac3" :parent-id="parentId" heading="Download">
            <accordion-wrapper id="child-3">
                <accordion-item
                    id="ac-ci-3-1"
                    parent-id="child-3"
                    heading="How to download selected page and the entire edition?"
                >
                    <p>After login to e-Paper,</p>
                    <ol>
                        <li>Click on menu icon select page to download the PDF of selected page.</li>
                        <li>Click on menu icon edition to download the PDF of Entire Edition.</li>
                    </ol>
                </accordion-item>
                <accordion-item
                    id="ac-ci-3-2"
                    parent-id="child-3"
                    heading="Will I be able to view or download past editions?"
                >
                You can access last 60 days issues of all eleven editions of The Hindu & Business line (Chennai edition only) from the current date in the e-Paper archives. You can always select a back date from the calendar on the tool bar or ‘Change Date’ from the left menu.
                </accordion-item>
                <accordion-item
                    id="ac-ci-3-3"
                    parent-id="child-3"
                    heading="When I try to download an article, I get it as html. How do I download an article as PDF?"
                >
                    <p>After login to e-Paper</p>
                    <ol>
                        <li>Select the desired page and select the article you wish to read</li>
                        <li>Click on icon to see the image view of the article icon to see the image view of the article</li>
                        <li>Click on to download the PDF version of the article to download the PDF version of the article</li>
                        <li>Clicking on in article view will download the html version of the article in all devices except iOS</li>
                    </ol>
                </accordion-item>
                <accordion-item
                    id="ac-ci-3-4"
                    parent-id="child-3"
                    heading="How do I download the e-Paper on a mobile?"
                >
                    <p>After login to e-Paper</p>
                    <ol>
                        <li>Select the page that you desire to read from the thumbnails</li>
                        <li>Click icon on the top left of the page icon on the top left of the page</li>
                        <li>Select ‘Download current page’ if you wish to download the current page</li>
                        <li>Select ‘Download current edition’ if you wish to download the whole edition</li>
                    </ol>
                </accordion-item>
                <accordion-item
                    id="ac-ci-3-5"
                    parent-id="child-3"
                    heading="Will I still be able to view the downloaded PDF pages after my subscription is over?"
                >Yes</accordion-item>
            </accordion-wrapper>
        </accordion-item>
        <!-- Item 4 -->
        <accordion-item id="ac4" :parent-id="parentId" heading="Subscription">
            <accordion-wrapper id="child-4">
                <accordion-item
                    id="ac-ci-4-1"
                    parent-id="child-4"
                    heading="I have subscribed for the UPSC Special plan, but I am not able to access Frontline?"
                >Frontline will become accessible within 48 hours of subscribing. Please check your inbox, as the username & password for the Frontline will be sent in a separate email.</accordion-item>
                <accordion-item
                    id="ac-ci-4-2"
                    parent-id="child-4"
                    heading="I have an active subscription. What will happen if I renew before the due date? Will the remaining days of my current subscription expire?"
                >The new subscription period will start only after the end of your current subscription. So, the remaining days will not get expired. For example, if your monthly plan gets over on 31st of January and you choose to recharge on 26th of January. The new plan will start only from 1st of February.</accordion-item>
                <accordion-item
                    id="ac-ci-4-3"
                    parent-id="child-4"
                    heading="How to renew e-Paper subscription?"
                >
                    <p>After login to e-Paper,</p>
                    <ol>
                        <li>Click on the left menu icon menu icon and select the ‘Subscription’ option</li>
                        <li>Select the number of days that you want to subscribe to</li>
                        <li>In the next screen make sure that you have selected the correct option. Make sure that your renewal start date is correct. If not, click on the calendar icon calendar icon to select the desired date</li>
                        <li>Click ‘Next’ to go to the payment gateway to make the payment</li>
                    </ol>
                </accordion-item>
                <accordion-item
                    id="ac-ci-4-4"
                    parent-id="child-4"
                    heading="Why are you charging for e-Paper?"
                >There's a lot of effort that goes into reporting, publishing, and distributing news truthfully and fairly. The Hindu's seamless coverage of news is a result of the round-the-clock efforts of hundreds of journalists - reporters, editors, fact-checkers, photographers, videographers, publishers - as well as many support staff. In addition to this we do incur costs associated with Software development, Bandwidth, AMC cost and Maintenance of database & server.</accordion-item>
                <accordion-item
                    id="ac-ci-4-5"
                    parent-id="child-4"
                    heading="Can my subscription be automatically renewed?"
                >
                    No, your subscription will not be auto-renewed. Once your subscription expires, you need to revisit the Subscriptions Plan page and select a plan.
                    Before your subscription expires, we will alert you via SMS or e-mail and share an update on your dashboard, so that you’re kept informed about this.
                    You have complete control over your subscription and can contact us at epapercare@thehindu.co.in to change or cancel at any time.
                </accordion-item>
                <accordion-item
                    id="ac-ci-4-6"
                    parent-id="child-4"
                    heading="I have made the payment, but my plan is not active"
                >
                    <p>If your payment has gone through but you’re still unable to activate your plan, send us an email with the following details:</p>
                    <ol>
                        <li>Transaction ID</li>
                        <li>Transaction ID</li>
                        <li>Plan Name</li>
                        <li>Your Registered ID</li>
                        <li>Date of transaction</li>
                        <li>Your Bank Name</li>
                        <li>Mode of transaction (Debit Card, Credit Card, e-Wallet)</li>
                    </ol>
                </accordion-item>
                <accordion-item
                    id="ac-ci-4-6"
                    parent-id="child-4"
                    heading="What are the advantages of e-Paper?"
                >
                    <ol>
                        <li>The printed edition will be available in full.</li>
                        <li>The individual articles can be seen in pdf/ image/text format and fully searchable.</li>
                        <li>Newspaper column format has been preserved.</li>
                        <li>Classified sections are fully searchable and easy to preserve.</li>
                        <li>Ability to provide an easy access for back dated edition pages (60 days’ archive)
The Read offline feature to download the entire edition in one go and can be preserved for future reference.</li>
                        <li>Ability to send article by email. (The recipient does not require subscription)</li>
                    </ol>
                </accordion-item>
            </accordion-wrapper>
        </accordion-item>
        <!-- Item 5 -->
        <accordion-item id="ac5" :parent-id="parentId" heading="All Access">
            <accordion-wrapper id="child-5">
                <accordion-item
                    id="ac-ci-5-1"
                    parent-id="child-5"
                    heading="What is All Access pass?"
                >
                    <p>All Access Pass enables you to access all the Digital subscriptions of The Hindu Group in One Single Discounted Plan. Once you subscribe, you get access to the following products:</p>
                    <ol>
                        <li>
                            The Hindu e-paper: Read The Hindu newspaper in a digital format anytime, anywhere (60 day's archive)
                        </li>
                        <li>
                            The Hindu BusinessLine e-paper: Read The Hindu BusinessLine newspaper in a digital format at your own convenience (60 day's archive)
                        </li>
                        <li>
                            The Hindu: Experience the ad-free version of The HIndu with personalised recommendations, daily briefings and much more.
                        </li>
                        <li>
                            The Hindu BusinessLine: Get your daily dose of business news along with full access to signature sections such as BL Portfolio, BLink, Clean Tech, Emerging Entrepreneurs & Editorials.
                        </li>
                        <li>
                            Sportstar: Browse through our list of esteemed columnist articles, get full access to our archives along with access to our seasonal posters and much more.
                        </li>
                        <li>
                            Frontline: Get access to premium articles with an in-depth analysis of issues and events within Indian and around the world.
                        </li>
                        <li>
                            The Hindu Crossword+: Get unlimited access to all puzzles and archival content. Crosswords and games from The Guardian, The Hindu, Sportstar, One-down puzzles, Sudoko and much more.
                        </li>
                    </ol>
                </accordion-item>
                <accordion-item
                    id="ac-ci-5-2"
                    parent-id="child-5"
                    heading="Does the All Access Pass include the print edition?"
                >The All access Pass is a Digital Pass and does not include the print subscription.</accordion-item>
            </accordion-wrapper>
        </accordion-item>
    </accordion-wrapper>
</template>

<script>

import TheHeader from '@/components/commonHeader.vue'
import PageInfoNav from '@/components/PageInfoNav';
import AccordionWrapper from '@/components/AccordionWrapper';
import AccordionItem from '@/components/AccordionItem';

export default {
    components: {
        TheHeader,
        PageInfoNav,
        AccordionWrapper,
        AccordionItem
    },
    data() {
        return {
            parentId: "faq-top-level"
        }
    }
}

</script>

<style scoped>
.accordion-body {
    font-size: 12px;
}
</style>