<template>
<section>
  <div class="container">
    <h2 class="section-title">See what our users say</h2>
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12" id="usersays">
      </div>
    </div>
  </div>
</section>
</template>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Serif:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&family=Roboto:wght@300;700&display=swap");
* {
  font-family: "Roboto", Regular;
  font-weight: normal;
}

section {
  display: block;
  clear: both;
  width: 100%;
  margin: 60px auto;
}
section.gray-bg {
  padding: 60px 0;
  background: #f1f1f1;
}

@media screen and (max-width: 570px) {
  section {
    margin: 30px auto;
  }
  section.gray-bg {
    padding: 30px 0;
  }
}
@media screen and (max-width: 1000px) and (min-width: 571px) {
  section {
    margin: 45px auto;
  }
  section.gray-bg {
    padding: 45px 0;
  }
}
.container {
  max-width: 1300px;
}

.section-title {
  font-family: "IBM Plex Serif", Bold;
  font-weight: bold;
  color: #000;
  font-size: 40px;
  text-align: center;
  margin: 0 auto 30px;
}

@media screen and (max-width: 570px) {
  .section-title {
    font-size: 22px;
    margin: 0 auto 20px;
  }
}
@media screen and (max-width: 1000px) and (min-width: 571px) {
  .section-title {
    font-size: 32px;
    margin: 0 auto 25px;
  }
}
</style>