<template>
  <side-bar-right @route-event="routeTo($event)" />
  <side-bar-left :menu-items="leftSidebarMenus" />
  <the-header></the-header>

    <div class="loader d-flex justify-content-center align-items-center" v-if="isLoading">
        <div class="spinner-border" role="status"></div>
    </div>
    <div ref="readerIframe" v-if="isIframeUrlNotNull">
      <iframe
          id="reader-iframe"
          v-if="isIframeUrlNotNull"
          :src="iframeUrl"
          frameborder="0"
          @load="registerIframeOnLoadActions"
      ></iframe>
    </div>
    <div class="p-3 d-flex justify-content-center my-5" v-else>
        <p v-if="editionsList.length == 0">
            No issues available {{region.title}} for this date: {{ date }}
       </p>
    </div>
    <div v-if="isIframeUrlNotNull">
      <div v-if="showCloseButton" class="btnPos" id="open_in_app">
        <button v-if="isIOS" class="ios-button">
          <div>
              <span onclick="window.location.href='https://businessline.page.link/DtUc';">
            <img class="apple" :src="publicPath + 'icons/apple.svg'" />
            Open in App</span>
            <img
              @click="closeButton"
              class="closeWhite"
              :src="publicPath + 'icons/closeWhite.svg'"
            />
          </div>
        </button>
      </div>
      <div v-if="showCloseButton" class="btnPos">
        <button v-if="isAndroid" class="ios-button">
          <div>
              <span onclick="window.location.href='https://businessline.page.link/DtUc';">
            <img class="apple" :src="publicPath + 'icons/google-play.svg'" />
            Open in App</span>
            <img
              @click="closeButton"
              class="closeWhite"
              :src="publicPath + 'icons/closeWhite.svg'"
            />
          </div>
        </button>
      </div>
    </div>
    <div id="curtain-raiser"></div>
    <session-logout-prompt
    :device_info="devices_info"
    v-show="isSessionLimitExceeded"
  ></session-logout-prompt>
    <google-sign-in></google-sign-in>
    <!-- <footer-page></footer-page> -->
</template>

<script>

import { mapGetters, mapActions } from 'vuex'

import TheHeader from '@/components/ReaderPage/MainNavBar.vue'
import SidebarProfile from '@/components/ReaderPage/sidebarMenus/SidebarProfile.vue'
import SidebarMenu from '@/components/ReaderPage/sidebarMenus/SidebarMenu.vue'
import SessionLogoutPrompt from "@/components/SessionLogoutPrompt.vue";
import GoogleSignIn from '@/components/ReaderPage/GoogleSignIn.vue'
// import FooterPage from '@/components/RXLoginPage/FooterPage.vue'
import Events from '@/utils/events'
import CallBacks from '@/utils/eventCallBacks'

import LeftSideBarMenuItems from '@/utils/leftSidebarMenuItems'

export default {
  components: {
      SideBarRight: SidebarProfile,
      TheHeader,
      SideBarLeft: SidebarMenu,
      GoogleSignIn,
      // FooterPage,
      SessionLogoutPrompt
  },
  data() {
      return {
          publicPath: process.env.BASE_URL,
          leftSidebarMenus: LeftSideBarMenuItems,
          ua: navigator.userAgent,
          isIOS: false,
          isAndroid: false,
          showCloseButton: true,
          storageKey: "closeButtonTimestamp",
    
      }
  },
  computed: {
      ...mapGetters('reader', {
          region: 'getRegion',
          isLoading: 'getLoadingState',
          iframeUrl: 'getIframeUrl',
          date: 'getDate',
          regionsList: "getRegionsList",
          editionsList: "getEditionsList",
          isSessionLimitExceeded: "getSessionLimitExceeded",
          devices_info: "getSessionDevicesInfo",
      }),
      isIframeUrlNotNull() {
          this.changeLoadingState(true)
          if (this.iframeUrl === 'null') this.changeLoadingState(false)
          return this.iframeUrl !== 'null'
      },
  },
  watch: {
  iframeUrl() {
      const oldIframe = this.$refs.readerIframe?.firstElementChild ?? null
      if (oldIframe != null) {
          const newIframe = window.document.createElement("iframe")
          newIframe.setAttribute("src", this.iframeUrl)
          newIframe.setAttribute("id", "reader-iframe")
          oldIframe.remove()
          this.$refs.readerIframe.appendChild(newIframe)
          this.registerIframeOnLoadActions(newIframe)
      }
      }
  },
  methods: {
      ...mapActions('reader',[
          'setInitialIframeUrl',
          'changeLoadingState'
      ]),
      routeTo(val) {
          window.$('#myaccountBtn').click()
          this.$router.push(`/${val}`)
      },
      registerIframeOnLoadActions(ev) {
          this.$logger.debug("Iframe load event");
          this.changeLoadingState(false)
          this.setIframeHeight()
          try {
              /**
               * All events exported from `src/utils/event.js` are checked.
               * 
               * The `_registerEventListener` function is called to check
               * whether any `CallBack` is defined for the particular `event`
               * exported from the `src/utils/event.js`.
               */
              Events.forEach(eventID => // Each `eventID` is checked for any associated `CallBack`
              this._registerEventListener(ev.target ?? ev, eventID)
              );
          } catch (error) {
              this.$logger.error("Error from eventListeners: ", error)
          }
      },
      /**
       * Pre-calculate iFrame height, to
       * 
       *      1. Avoid text blurring while viewing PDF.
       *      2. To avoid unwanted scrolling issues.
       */
      setIframeHeight() {
          const navbarHeight = document.getElementById('nav-bar').clientHeight
          const offset = 6
          const requiredHeight = innerHeight - navbarHeight - offset
          document.getElementById("reader-iframe").style.height = `${requiredHeight}px`
      },
      closeButton() {
        this.showCloseButton = false;
          document.getElementById("open_in_app").style.display = "none";
        const timestamp = new Date().getTime();
        localStorage.setItem(this.storageKey, timestamp.toString());
      },
      /**
       * Register eventListeners for all events in the [Events] array
       * 
       * @param HTMLElement target element to attach the listener
       * @param String eventId to listen for
       */
      _registerEventListener(target, eventID) {
          // Register event listener for the [eventID]
          target.contentWindow.addEventListener(eventID, eventDetails => {
              // Log recieved event details...
              this.$logger.debug(`${eventDetails.type} event occured...`)
              this.$logger.debug(eventDetails)
              // Register the respective callback for that [eventID]
              const cb = CallBacks[eventID]
              // Execute the callback with [store] and [eventDetails] as params
              if (cb) cb(this.$store, eventDetails)
              // GTM Event tracking
              CallBacks["common_event_tracking"](eventDetails)
          })
      }
  },
  created() {
      this.$logger.info("ReaderPage component created..")
      this.setInitialIframeUrl();

      const lastCloseTimestamp = localStorage.getItem(this.storageKey);
    if (lastCloseTimestamp) {
      const timeDiff = new Date().getTime() - parseInt(lastCloseTimestamp);
      if (timeDiff < 24 * 60 * 60 * 1000) {
        this.showCloseButton = false;
      }
    }

    const userAgent = navigator.userAgent;

    // Open in app ios
    if (
      userAgent.match(/iPad/i) ||
      userAgent.match(/iPhone/i) ||
      userAgent.match(/iPod/i)
    ) {
      this.isIOS = true;
    }

   // Open in app Android
if (userAgent.match(/Android/i)) {
  this.isAndroid = true;
}
//   this.isIOS = true;
//   this.isAndroid = true;
},
  mounted() {
      this.setIframeHeight();
      document.body.style.backgroundColor="#f1f1f1"
  }
}
</script>

<style>
/* .reader {
  width: 100%;
  height: 100vh;
  background-color: lightgray;
  position: relative;
  overflow: hidden;
} */
#reader-iframe {
  width: 100%;
  overflow: hidden;
  height: 100vh;
}
.loader {
  height: 90vh;
}
.ios-button {
  position: relative;
  background-color: black;
  border-radius: 15px;
  border: none;
  color: white;
  padding: 5px;
  width: 149px;
  text-align: center;
  font-size: 12px;
}
.apple {
  padding-right: 5px;
  width: 15px;
  height: 19px;
}
.closeWhite {
  padding-left: 5px;
}
.btnPos {
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#open_in_app {
  display: none;
}

</style>