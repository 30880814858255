<template>
	<the-header></the-header>
	<TopBanner></TopBanner>
    <SubscribeBox></SubscribeBox>
    <SubscribeboxStatic></SubscribeboxStatic>
    <QuotesBlock></QuotesBlock>
    <FaqContact></FaqContact>
    <FooterPage></FooterPage>
</template>

<script>
import TheHeader from '@/components/RXLoginPage/HeaderPage.vue'
import TopBanner from '@/components/RXLoginPage/TopBanner.vue'
import SubscribeBox from '@/components/RXLoginPage/SubscribeBox.vue'
import SubscribeboxStatic from '@/components/RXLoginPage/SubscribeboxStatic.vue'
import QuotesBlock from '@/components/RXLoginPage/QuotesBlock.vue'
import FaqContact from '@/components/RXLoginPage/FaqContact.vue'
import FooterPage from '@/components/RXLoginPage/FooterPage.vue'


export default {
	components: {
		TheHeader,
        TopBanner,
        SubscribeBox,
        SubscribeboxStatic,
        QuotesBlock,
        FaqContact,
        FooterPage
    },
	mounted() {
		window.tp?.push(["init", () => {
			if (window.tp.pianoId?.isUserValid()) {
				this.$router.push('/reader')
			}
		}]);
	}
}
</script>
