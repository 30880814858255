<template>
<header class="header">
    <div class="container">
        <div class="btn-part">
            <div class="btns">
                <button type="button" id="trial-btn" class="btn btn-free-trial">FREE TRIAL<span class="slider"></span></button>
                <button type="button" class="btn btn-premium" disabled style="display: none;"><img src="">
                    PREMIUM</button>
                    <a href="#piano-subscription">
                            <BaseButton
                                device-type="desktop"
                                :class-names="['btn-subscribe']"
                            >SUBSCRIBE
                            </BaseButton>
                        </a>
                        <BaseButton
                            device-type="desktop" 
                            :class-names="['btn-signup']" 
                            @click="showLoginScreen"
                        >LOGIN
                        </BaseButton>
                <button type="button" class="btn btn-account" style="display: none;">
                    ACCOUNT
                    <img src=""></button>
            </div>
        </div>
        <div class="logo-part">
            <div class="logo">
                <img :src="logoPath" alt="BusinessLine Logo" />
                <!--<span class="slogan">DIGITAL ACCESS TO DAILY EDITIONS OF THE HINDU NEWSPAPER</span>
                -->
                <div class="date-time">Monday <br>February 13, 2023</div>
                <div class="epaper-text">ePaper</div>
            </div>
            

        </div>
    </div>
    <div class="mobile-menu">
        <div class="mobile-btn-part">
            <button type="button" class="btn btn-free-trial">FREE TRIAL<span class="slider"></span></button>
            <button type="button" class="btn btn-premium" disabled style="display: none;"><img src="">
                PREMIUM</button>
            <button type="button" class="btn menu-btn"><img class="menu-hamber" src="../../assets/image/menu-hamber-icon.svg" alt="Menu Icon" title="Menu"></button>
        </div>
    </div>
   
</header>
<div class="dropdown">
    <div class="container">
        <div class="flex-container">
            <button type="button" class="btn btn-premium" disabled style="display: none;"><img src="">
                PREMIUM</button>
                  <BaseButton
                      device-type="desktop"
                      :class-names="['btn btn-subscribe']"
                  ><a href="#piano-subscription">SUBSCRIBE</a>
                  </BaseButton>
                  <BaseButton
                      device-type="desktop" 
                      :class-names="['btn btn-signup']" 
                      @click="showLoginScreen"
                  >LOGIN
                  </BaseButton>
            <button type="button" class="btn btn-account" style="display: none;">
                ACCOUNT
                <img src=""></button>
        </div>
    </div>
</div>
<div class="header-end"></div>
</template>
<script>
import BaseButton from './BaseButton.vue'

export default {
  data() {
    return {
      logoPath: process.env.BASE_URL + process.env.VUE_APP_LOGO_RX_FILE
    }
  },
  components: {
    BaseButton
  },
  mounted(){
    window.$(document).ready(function(){
      var offset1 = window.$(".header-end").outerHeight();
      var offset2 = window.$(".header").outerHeight();
      window.$(this).scroll(function() {
        if (window.$(window).scrollTop() > offset1) {
          window.$(".header").addClass("on-scroll");
        }
        else if (window.$(window).scrollTop() < offset2){
          window.$(".header").removeClass("on-scroll");
        }
      });
      window.$(".menu-btn").click(function(){
        window.$(".menu-btn, .dropdown").toggleClass("opened");
        return false;
      });
    });
    window.$(".btn-subscribe").click(function(){
    window.$('html, body').animate({
      scrollTop: window.$(".plans-section").offset().top - 150
    }, 100);
    });
  },
  methods: {
    showLoginScreen() {
      this.$store.dispatch('auth/login')
    }
  }
}
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Serif:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&family=Roboto:wght@300;700&display=swap");
* {
  font-family: "Roboto", Regular;
  font-weight: normal;
}

.container {
    max-width: 1300px;
}
.header {
  z-index: 15;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.1607843137);
  padding: 15px 0;
  position: absolute;
  top: 0;
  left: 0;
}

.btn-part {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  margin: 10px auto;
}

.play-quiz {
  display: block;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.2px;
  color: #C00;
  text-align: center;
  margin-top: 3px;
  margin-right: 20px;
  position: relative;
  transition: all 0.3s;
}
.play-quiz::after {
  content: "";
  width: 0%;
  height: 1px;
  background: #C00;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  transition: all 0.3s;
}
.play-quiz:hover {
  color: #C00;
  text-decoration: none;
}
.play-quiz:hover::after {
  width: 100%;
}

.logo{
  max-width: none;
}
.logo-part {
  display: block;
  width: max-content;
  position: relative;
  text-align: center;
  margin: 25px auto 20px;
}
.logo-part .logo {
  display: block;
}
.logo-part .logo img {
  width: 467px;
  height: 60px;
}
.logo-part .logo .slogan {
  display: block;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.2px;
  color: #C00;
  text-align: center;
  margin-top: 10px;
}
.logo-part .logo .date-time {
  position: absolute;
  display: block;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  letter-spacing: 0.5px;
  color: #000000;
  text-align: right;
  width: max-content;
  top: 12px;
  right: calc(100% + 10px);
}
.logo-part .logo .epaper-text {
  position: absolute;
  display: block;
  font-family: "Roboto", sans-serif;
  font-size: 30px;
  color: #000000;
  font-weight: bold;
  text-align: left;
  width: max-content;
  top: 10px;
  left: calc(100% + 10px);
}

.btn-subscribe, .btn-signup, .btn-account, .btn-premium, .go-to-search, .btn-free-trial {
  background: #ffffff;
  border-radius: 3px;
  border: 1px solid #C00;
  color: #C00;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  width: 147px;
  padding: 9px 0 6px 0;
  transition: ease-out 0.3s;
  margin-right: 5px;
}
.btn-subscribe:hover, .btn-subscribe:focus, .btn-signup:hover, .btn-signup:focus, .btn-account:hover, .btn-account:focus, .btn-premium:hover, .btn-premium:focus, .go-to-search:hover, .go-to-search:focus, .btn-free-trial:hover, .btn-free-trial:focus {
  color: #ffffff;
  text-decoration: none;
  box-shadow: none;
}

.btn-subscribe {
  background: #C00;
  color: #ffffff;
  transition: ease-out 0.3s;
  margin-right: 10px;
  box-shadow: inset 0 0 0 0 #ffffff;
}
.btn-subscribe:hover, .btn-subscribe:focus {
  color: #C00;
  box-shadow: inset 200px 0 0 0 #ffffff;
}

.btn-free-trial {
  background: none;
  border: none;
  color: #000000;
  font-weight: bold;
  width: max-content;
  height: auto;
  padding: 0;
  line-height: normal;
  margin-right: 24px;
  margin-left: 32px;
  position: relative;
  top: 2px;
}
.btn-free-trial::before {
  position: absolute;
  content: "";
  background: url(../../assets/image/bl-circle-blue.svg) no-repeat center;
  top: -5px;
  left: -31px;
  width: 26px;
  height: 26px;
  background-size: 26px auto;
}
.btn-free-trial .slider {
  position: absolute;
  display: block;
  left: 0;
  top: 95%;
  margin: 0 auto;
  height: 2px;
  background-color: #C00;
  width: 0%;
  transition: width 0.3s ease;
}
.btn-free-trial:hover, .btn-free-trial:focus, .btn-free-trial:active {
  color: #000000;
}
.btn-free-trial:hover .slider, .btn-free-trial:focus .slider, .btn-free-trial:active .slider {
  width: 100%;
}

.btn-signup {
  transition: ease-out 0.3s;
  box-shadow: inset 0 0 0 0 #C00;
  margin-right: 0;
}
.btn-signup:hover, .btn-signup:focus {
  color: #ffffff;
  box-shadow: inset 200px 0 0 0 #C00;
}

.btn-account {
  background: #ffffff;
  border: 1px solid #C00;
  color: #C00;
  margin-right: 0;
  transition: ease-out 0.3s;
  box-shadow: inset 0 0 0 0 #C00;
}
.btn-account img {
  position: relative;
  margin-left: 5px;
  top: -2px;
}
.btn-account:hover, .btn-account:focus {
  color: #ffffff;
  box-shadow: inset 200px 0 0 0 #C00;
}
.btn-account:hover img, .btn-account:focus img {
  content: url(../../assets/image/account-btn-icon-white.svg);
}

.btn-premium {
  background: transparent;
  border: none;
  position: relative;
  font-weight: bold;
  color: #000000;
  padding: 7px 0 2px 0;
}
.btn-premium img {
  width: 29px;
  height: 29px;
  margin-right: 5px;
  top: -3px;
  position: relative;
}
.btn-premium:disabled {
  opacity: 1;
}

.header-end {
  height: 178px;
}

.on-scroll {
  position: fixed;
  top: 0;
}
.on-scroll .btn-part {
  display: flex;
  width: 100%;
  justify-content: right;
  align-items: center;
  margin: 10px auto;
}
.on-scroll .logo-part {
  margin: 0;
  position: absolute;
  top: 13px;
}
.on-scroll .logo-part .logo {
  display: block;
}
.on-scroll .logo-part .logo img {
  content: url(../../assets/image/bl-circle-blue.svg);
  width: 59px;
  height: 59px;
}
.on-scroll .logo-part .logo .date-time {
  display: none;
}
.on-scroll .logo-part .logo .epaper-text {
  font-size: 22px;
  top: 14px;
  left: calc(100% + 7px);
}

@media screen and (max-width: 570px) {
  .header {
    padding: 10px 0;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
  }

  .header-end {
    height: 56px;
  }

  .btn-part {
    display: none;
  }

  .play-quiz {
    display: none;
  }

  .logo-part {
    text-align: left;
    margin: 5px 0 7px;
  }
  .logo-part .logo {
    display: block;
  }
  .logo-part .logo img {
    width: 150px;
    height: 19px;
  }
  .logo-part .logo .slogan {
    display: none;
  }
  .logo-part .logo .date-time {
    display: none;
  }
  .logo-part .logo .epaper-text {
    font-size: 10px;
    left: calc(100% + 5px);
    top: 7px;
  }

  .on-scroll .btn-part {
    display: none;
  }
  .on-scroll .logo-part {
    margin: 0;
    position: relative;
    top: 0;
  }
  .on-scroll .logo-part .logo {
    display: block;
  }
  .on-scroll .logo-part .logo img {
    width: 36px;
    height: 36px;
  }
  .on-scroll .logo-part .logo .date-time {
    display: none;
  }
  .on-scroll .logo-part .logo .epaper-text {
    font-size: 14px;
    top: 8px;
    left: calc(100% + 5px);
  }
}
@media screen and (max-width: 1000px) and (min-width: 571px) {
  .header {
    padding: 15px 0;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.13);
  }

  .header-end {
    height: 88px;
  }

  .btn-part {
    display: none;
  }

  .logo-part {
    text-align: left;
    margin: 10px 0 10px;
  }
  .logo-part .logo img {
    width: 280px;
    height: 36px;
  }
  .logo-part .logo .slogan {
    display: none;
  }
  .logo-part .logo .date-time {
    font-size: 10px;
    text-align: left;
    top: 22px;
    left: calc(100% + 10px);
  }
  .logo-part .logo .date-time br {
    display: none;
  }
  .logo-part .logo .epaper-text {
    font-size: 18px;
    top: -3px;
    left: calc(100% + 8px);
  }

  .on-scroll .btn-part {
    display: none;
  }
  .on-scroll .logo-part {
    margin: 0;
    position: relative;
    top: 0;
  }
  .on-scroll .logo-part .logo {
    display: block;
  }
  .on-scroll .logo-part .logo img {
    width: 36px;
    height: 36px;
  }
  .on-scroll .logo-part .logo .date-time {
    display: none;
  }
  .on-scroll .logo-part .logo .epaper-text {
    font-size: 14px;
    top: 8px;
    left: calc(100% + 5px);
  }
  .on-scroll .mobile-menu {
    top: 15px;
  }
  .on-scroll + .dropdown.opened {
    transform: translateY(66px);
  }
}
.mobile-menu {
  position: absolute;
  top: 10px;
  right: 5px;
  display: none;
}
.mobile-menu .mobile-btn-part {
  text-align: right;
}
.mobile-menu .mobile-btn-part .menu-btn {
  width: auto;
  height: 35px;
  outline: none;
  border: none;
}
.mobile-menu .mobile-btn-part .menu-btn img {
  width: 25px;
  height: 14px;
}
.mobile-menu .mobile-btn-part .menu-btn.opened img {
  content: url(../../assets/image/menu-close-icon.svg);
}
.mobile-menu .mobile-btn-part .menu-btn:hover, .mobile-menu .mobile-btn-part .menu-btn:focus {
  outline: none;
  border: none;
  box-shadow: none;
}
.mobile-menu .mobile-btn-part .btn {
  font-size: 10px;
  position: relative;
  display: inline-block;
}
.mobile-menu .mobile-btn-part .btn.btn-premium {
  width: max-content;
  padding: 0;
}
.mobile-menu .mobile-btn-part .btn.btn-premium img {
  width: 20px;
  height: 20px;
  margin-right: 4px;
  top: -1px;
}
.mobile-menu .mobile-btn-part .btn.btn-free-trial {
  width: max-content;
  margin-right: 0;
}
.mobile-menu .mobile-btn-part .btn.btn-free-trial::before {
  left: -25px;
  width: 20px;
  height: 20px;
  background-size: 20px auto;
  top: -4px;
}

.dropdown {
  padding: 20px 0 25px;
  background: #ffffff;
  width: 100%;
  position: fixed;
  transform: translateY(-120px);
  transition: all 0.5s;
  z-index: 9;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
  display: none;
}
.dropdown.opened {
  transform: translateY(56px);
}
.dropdown .flex-container {
  display: flex;
}
.dropdown .btn {
  font-size: 12px;
  position: relative;
  display: inline-block;
  width: 46%;
  margin: 0 2%;
}
.btn-subscribe a{
  color: #FFF;
  text-decoration: none;
  padding: 9px;
}
.btn-subscribe a:hover{
  color: #C00;
}

.dropdown .btn.btn-premium img {
  width: 25px;
  height: 25px;
  top: -1px;
}
.dropdown .play-quiz {
  display: block;
  margin: 0px auto 20px;
  text-align: center;
  width: max-content;
}

@media screen and (max-width: 570px) {
  .mobile-menu, .dropdown {
    display: block;
  }
}
@media screen and (max-width: 1000px) and (min-width: 571px) {
  .mobile-menu {
    position: absolute;
    top: 26px;
    right: 15px;
    display: block;
  }
  .mobile-menu .mobile-btn-part {
    text-align: right;
  }
  .mobile-menu .mobile-btn-part .btn {
    font-size: 12px;
    position: relative;
    display: inline-block;
  }
  .mobile-menu .mobile-btn-part .btn.btn-premium {
    width: max-content;
    padding: 0;
  }
  .mobile-menu .mobile-btn-part .btn.btn-premium img {
    width: 22px;
    height: 22px;
    margin-right: 6px;
    top: -1px;
  }
  .mobile-menu .mobile-btn-part .btn.btn-free-trial {
    width: max-content;
    margin-right: 0;
  }
  .mobile-menu .mobile-btn-part .btn.btn-free-trial::before {
    left: -27px;
    width: 22px;
    height: 22px;
    background-size: 22px auto;
    top: -4px;
  }

  .dropdown {
    display: block;
    transform: translateY(-125px);
  }
  .dropdown.opened {
    transform: translateY(88px);
  }
  .dropdown .flex-container {
    display: flex;
  }
  .dropdown .btn {
    font-size: 14px;
  }
  .dropdown .btn.btn-premium img {
    width: 25px;
    height: 25px;
    top: -1px;
  }
}

</style>